export default class FMaterialGroup1 {
  constructor(
    id,
    kode1,
    description,
    phone=0,

    avatarImage,
    fdivisionBean,
    fsalesmanBean,
    statusActive=true,

    email,
    webSite,
    instagram,

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.description = description;
    this.phone = phone;

    this.avatarImage = avatarImage;
    this.statusActive = statusActive;
    this.fdivisionBean = fdivisionBean;
    this.fsalesmanBean = fsalesmanBean;

    this.email = email;
    this.webSite = webSite;
    this.instagram= instagram;


    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
